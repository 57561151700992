import { usePostHog } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import useProfileStore from '@/store/profile/useProfileStore';
import { ErrorHandlingPostHog } from '@/shared/types/posthog';

export default function usePostHogEvent() {
  const profileStore = useProfileStore();

  const postHogParams: ErrorHandlingPostHog = {
    name: profileStore.profile.name,
  };

  const postHog = usePostHog(postHogParams);

  return postHog;
}
