import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { ErrorsData, SubLocations, ErrorLog } from '@/shared/types/errors';
import errorLogService from '@/shared/services/errorLogService';

const useErrorLogStore = defineStore('errorLog', () => {
  const listOfErrors: ErrorsData = reactive({
    errors: {
      Transelevators: {} as SubLocations,
      'Outros Locais': {} as SubLocations,
    },
  });

  const errorLog: ErrorLog = reactive({
    errorLocation: '',
    errorSubLocation: '',
    errorType: '',
    errorCause: '',
    palletType: '',
    errorCorrectionTime: 0,
  });

  let valueOfCorrectionTime = '';

  const loadErrors = async () => {
    try {
      listOfErrors.errors = (await errorLogService.getErrors()).errors;
    } catch (e) {
      listOfErrors.errors = {
        Transelevators: {} as SubLocations,
        'Outros Locais': {} as SubLocations,
      };
    }
  };

  async function createErrorLog(error: ErrorLog): Promise<void> {
    return errorLogService.createErrorLog(error);
  }

  function $reset() {
    errorLog.errorLocation = '';
    errorLog.errorSubLocation = '';
    errorLog.errorType = '';
    errorLog.errorCause = '';
    errorLog.palletType = '';
    errorLog.errorCorrectionTime = 0;
    valueOfCorrectionTime = '';
  }

  return {
    listOfErrors,
    errorLog,
    valueOfCorrectionTime,
    loadErrors,
    createErrorLog,
    $reset,
  };
});

export default useErrorLogStore;
