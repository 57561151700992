/* istanbul ignore file */

import useProfileStore from '@/store/profile/useProfileStore';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

function preventDirectRouteChange(
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const profileStore = useProfileStore();
  const { token } = profileStore.profile;

  if (from.name && token) {
    next();
  } else {
    profileStore.$reset();
    next('/');
  }
}

const LoginView = () =>
  import(/* webpackChunkName: "errors" */ '@/views/LoginView/LoginView.vue');
const HomeView = () =>
  import(/* webpackChunkName: "errors" */ '@/views/HomeView/HomeView.vue');

const RegisterWorkView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/RegisterWorkHourView/RegisterWorkHourView.vue'
  );

const RegisterSuccessView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/RegisterSuccessView/RegisterSuccessView.vue'
  );

const RegisterMotivePauseView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/RegisterMotivePauseView/RegisterMotivePauseView.vue'
  );

const ErrorLocationView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorLocationView/ErrorLocationView.vue'
  );
const ErrorTypeView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorTypeView/ErrorTypeView.vue'
  );
const ErrorCauseView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorCauseView/ErrorCauseView.vue'
  );
const ErrorPalletTypeView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorPalletTypeView/ErrorPalletTypeView.vue'
  );
const ErrorConfirmationView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorConfirmation/ErrorConfirmationView.vue'
  );
const ErrorSuccessView = () =>
  import(
    /* webpackChunkName: "errors" */ '@/views/ErrorSuccessView/ErrorSuccessView.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: { bodyClass: 'background-login' },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/registerWorkHour',
    name: 'RegisterWork',
    component: RegisterWorkView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/registerSuccess',
    name: 'RegisterSuccess',
    component: RegisterSuccessView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/registerMotivePause',
    name: 'RegisteMotivePause',
    component: RegisterMotivePauseView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/location',
    name: 'ErrorLocation',
    component: ErrorLocationView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/type',
    name: 'ErrorType',
    component: ErrorTypeView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/cause',
    name: 'ErrorCause',
    component: ErrorCauseView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/palletType',
    name: 'ErrorPalletType',
    component: ErrorPalletTypeView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/errorConfirmation',
    name: 'ErrorConfirmation',
    component: ErrorConfirmationView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
  {
    path: '/errorSuccess',
    name: 'ErrorSuccess',
    component: ErrorSuccessView,
    meta: { bodyClass: 'background-default' },
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, next),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
