<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppHeader from '@/components/app/AppHeader/AppHeader.vue';
import AppAbortConfirmationModal from '@/components/app/AppAbortConfirmationModal/AppAbortConfirmationModal.vue';
import usePostHogEvent from '@/composables/usePostHogEvent/usePostHogEvent';
import useSharedStore from '@/store/shared/useSharedStore';
import useRegisterWorkStore from '@/store/registerWork/useRegisterWorkStore';
import { HeaderType } from '@/shared/types/generic';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';
import router from '../../router';

const sharedStore = useSharedStore();
const profileStore = useProfileStore();
const useRegisteWorkHour = useRegisterWorkStore();
const errorLog = useErrorLogStore();
const isDashBoard = ref(true);
const screenHeight = window.innerHeight;

function saveAutoCapturePostHog(eventName: string, execute: boolean) {
  if (execute) {
    usePostHogEvent().postHogAutoCaptureEvent(eventName);
  }
}

onMounted(() => {
  document.addEventListener('click', (event) => {
    const clickedElement = event.target as HTMLElement;
    const element = clickedElement.localName;
    const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
    saveAutoCapturePostHog(eventName || '', true);
  });
});

function toggleActiveTab(tab: string) {
  if (tab === 'Dashboard') {
    isDashBoard.value = true;
  } else {
    isDashBoard.value = false;
  }
}

onBeforeMount(() => {
  router.beforeEach((to) => {
    document.body.className = '';
    document.body.className = String(to.meta.bodyClass);
  });

  window.removeEventListener('click', (event) => {
    const clickedElement = event.target as HTMLElement;
    const element = clickedElement.localName;
    const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
    saveAutoCapturePostHog(eventName || '', false);
  });
});

const iframeURL = `${process.env.VUE_APP_IFRAME_URL}${process.env.VUE_APP_ORG}`;

const showCorrectionTime = computed({
  get(): boolean {
    return sharedStore.isModalVisible;
  },
  set(newValue: boolean): void {
    sharedStore.isModalVisible = newValue;
  },
});

watch(useRegisteWorkHour.registerPost, (newValue) => {
  if (
    !newValue.register &&
    !newValue.motiveRegister &&
    (!profileStore.profile.currentTab ||
      profileStore.profile.currentTab === HeaderType.DASHBOARD)
  ) {
    isDashBoard.value = true;
  }
});

watch(errorLog.errorLog, (newValue) => {
  if (
    !newValue.errorCause &&
    !newValue.errorLocation &&
    !newValue.errorSubLocation &&
    !newValue.errorType &&
    !newValue.palletType &&
    (!profileStore.profile.currentTab ||
      profileStore.profile.currentTab === HeaderType.DASHBOARD)
  ) {
    isDashBoard.value = true;
  }
});

defineExpose({
  toggleActiveTab,
  isDashBoard,
  saveAutoCapturePostHog,
  showCorrectionTime,
});
</script>

<template>
  <AppAbortConfirmationModal v-if="showCorrectionTime" />
  <AppHeader @toggle-active-tab="toggleActiveTab" />
  <div v-if="!isDashBoard"><router-view /></div>
  <div v-show="isDashBoard" class="container-iframe">
    <div>
      <iframe
        id="public-displays"
        title="publicdisplays"
        :src="iframeURL"
        class="iframe"
        :height="screenHeight - 68"
      ></iframe>
    </div>
  </div>
</template>

<style lang="scss">
.container-iframe {
  top: 68px;
}

.iframe {
  width: 100%;
  padding-top: 68px;
  border: none;
}
</style>
