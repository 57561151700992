import axios from '@/plugins/http/axios';
import { RegisterData, RegisterPayload } from '@/shared/types/registerWorking';

export default {
  getRegister(): Promise<RegisterData> {
    return axios.get('/working-hours');
  },

  createRegisteWork(register: RegisterPayload): Promise<void> {
    return axios.post('/working-hours', register);
  },
};
