<template>
  <div class="app-separator" />
</template>

<style scoped lang="scss">
.app-separator {
  height: 32px;
  border-bottom: 1px solid $gray-400;
}
</style>
