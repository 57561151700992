const vaibeLogo = '/img/vaibe-logo.svg';
const vaibeLogoWhite = '/img/vaibe-logo-white.svg';

const stepIdentification = '/img/identify-icon.svg';
const stepFix = '/img/fix-icon.svg';
const stepRegister = '/img/register-icon.svg';
const separator = '/img/separator.svg';

const edit = '/img/edit.svg';

const back = '/img/back.svg';

const forward = '/img/forward.svg';

const equipmentFailure = '/img/equipment-failure.svg';

const info = 'img/info.svg';

const homeIllustration = '/img/illustration_home.svg';

const successIllustration = '/img/illustration_success.svg';
const registerSuccess = '/img/register-success.svg';

export {
  vaibeLogo,
  vaibeLogoWhite,
  stepIdentification,
  stepFix,
  stepRegister,
  separator,
  edit,
  back,
  forward,
  equipmentFailure,
  info,
  registerSuccess,
  homeIllustration,
  successIllustration,
};
