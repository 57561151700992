import axios from 'axios';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { UserProfile } from '@/shared/types/profile';
import { HeaderType } from '@/shared/types/generic';
import { ResponsePostHog } from '@vaibe-github-enterprise/kd-kse-fe-commons';

const useProfileStore = defineStore('profile', () => {
  let profile = reactive({
    name: '',
    currentTab: HeaderType.DASHBOARD,
    role: '',
    token: '',
    avatarUrl: '',
  } as UserProfile);

  function $reset() {
    profile = { name: '', role: '', token: '', avatarUrl: '' };
  }

  function getPostHogKey(): Promise<ResponsePostHog> {
    const apiUrl = '/api/configs';
    return axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${profile.token}`,
      },
    });
  }

  async function login(qrCode: string) {
    await axios({
      method: 'post',
      data: qrCode,
      headers: { 'content-type': 'text/plain' },
      url: '/api/profiles',
    }).then((response) => {
      profile.name = response.data.name;
      profile.role = response.data.role;
      profile.token = response.data.token;
      profile.avatarUrl = response.data.avatarUrl;
    });
  }
  return { $reset, profile, login, getPostHogKey };
});

export default useProfileStore;
