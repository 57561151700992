export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
}

export enum HttpStatus {
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
}

export enum MediaDevice {
  FRONT_CAMERA = 'user',
  BACK_CAMERA = 'environment',
}

export enum HeaderType {
  DASHBOARD = 'Dashboard',
  ENTRIESEXITS = 'EntriesExitis',
  ERRORHANDLING = 'ErrorHandling',
}

export default Locale;
