import axios from '@/plugins/http/axios';
import { ErrorsData, ErrorLog } from '../types/errors';

export default {
  getErrors(): Promise<ErrorsData> {
    return axios.get('/errors');
  },

  createErrorLog(errorLog: ErrorLog): Promise<void> {
    return axios.post('/errors', errorLog);
  },
};
