<script setup lang="ts">
import { onMounted, ref } from 'vue';
import SplashScreen from '@/components/app/AppSplashScreen/AppSplashScreen.vue';
import MainView from '@/views/MainView/MainView.vue';

const showSplash = ref(true);
onMounted(() => {
  setTimeout(() => {
    showSplash.value = false;
  }, 3000);
});

defineExpose({
  showSplash,
});
</script>

<template>
  <SplashScreen v-if="showSplash" />
  <MainView v-else />
</template>
