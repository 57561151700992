/* istanbul ignore file */
import { createI18n } from 'vue-i18n';
import enUS from '@/locales/en_US.json';
import ptPT from '@/locales/pt_PT.json';
import { Locale } from '@/shared/types/generic';

const messages = {
  'en-US': { ...enUS },
  'pt-PT': { ...ptPT },
};

type MessageSchema = typeof enUS;

export default createI18n<[MessageSchema], Locale.US>({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || Locale.US,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.US,
  messages,
});
