import { defineStore } from 'pinia';
import { reactive } from 'vue';

const useSharedStore = defineStore('shared', () => {
  const isModalVisible = false;
  const state = reactive({
    timerId: 0,
  });

  async function clearTimer() {
    if (state.timerId) {
      clearTimeout(state.timerId);
      state.timerId = 0;
    }
  }

  async function setTimerId(_timerId: number) {
    clearTimer();
    state.timerId = _timerId;
  }

  return { isModalVisible, state, setTimerId, clearTimer };
});

export default useSharedStore;
