import { defineStore } from 'pinia';
import { HttpStatus } from '@/shared/types/generic';
import { reactive } from 'vue';

interface ErrorState {
  errorCode?: HttpStatus;
}

const useErrorStore = defineStore('error', () => {
  const errorState = reactive<ErrorState>({});

  function setErrorCode(errorCodeParameter: HttpStatus) {
    errorState.errorCode = errorCodeParameter;
  }
  return { errorState, setErrorCode };
});

export default useErrorStore;
