<script setup lang="ts">
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import useSharedStore from '@/store/shared/useSharedStore';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';

const sharedStore = useSharedStore();
const errorLogStore = useErrorLogStore();

const { t } = useI18n();

function resetErrorLog() {
  sharedStore.isModalVisible = false;
  errorLogStore.errorLog = reactive({
    errorLocation: '',
    errorSubLocation: '',
    errorType: '',
    errorCause: '',
    errorCorrectionTime: 0,
    palletType: '',
  });
}

defineExpose({
  resetErrorLog,
});
</script>

<template>
  <AppModal>
    <template #header>{{ t('modal.abortError.title') }}</template>
    <template #body>
      {{ t('modal.abortError.body') }}
    </template>
    <template #footer>
      <AppButton
        type="vaibe"
        size="L"
        :label="t('modal.abortError.button')"
        @click="resetErrorLog"
      />
    </template>
  </AppModal>
</template>
