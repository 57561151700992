import {
  RegisterData,
  RegisterPayload,
  RegisterRequest,
  RegisterWork,
} from '@/shared/types/registerWorking';
import { defineStore } from 'pinia';
import registeWorkService from '@/shared/services/registerWork/registerWorkService';
import { reactive, ref } from 'vue';

const useRegisterWorkStore = defineStore('registerWork', () => {
  const listRegister: RegisterData = reactive({
    register: {
      Turno: {} as RegisterWork,
      Pausa: {} as RegisterWork,
    },
  });

  const registerPost: RegisterRequest = reactive({
    register: '',
    motiveRegister: '',
  });

  const isLoading = ref(false);
  const isFailure = ref(false);

  const loadRegister = async () => {
    try {
      isLoading.value = true;
      listRegister.register = (await registeWorkService.getRegister()).register;
      isLoading.value = false;
    } catch (e) {
      isLoading.value = false;
      listRegister.register = {
        Turno: {} as RegisterWork,
        Pausa: {} as RegisterWork,
      };
    }
  };

  async function createRegisterWorkHour(
    register: RegisterPayload,
  ): Promise<void> {
    return registeWorkService.createRegisteWork(register);
  }

  return {
    registerPost,
    createRegisterWorkHour,
    loadRegister,
    isLoading,
    isFailure,
    listRegister,
  };
});

export default useRegisterWorkStore;
