import axios, { InternalAxiosRequestConfig } from 'axios';
import useProfileStore from '@/store/profile/useProfileStore';
import useErrorStore from '@/store/error/useErrorStore';
import { BusinessError, ResponseError } from '@/shared/types/utils';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig<unknown>> => {
    const profileStore = useProfileStore();

    const requestConfig: InternalAxiosRequestConfig = { ...request };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${profileStore.profile.token}`,
      'Source-Id': '4',
      Time: Date.now(),
      'K-Engage-Error-Handling-Version': process.env.VUE_APP_VERSION,
    };

    return requestConfig;
  },
);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorStore = useErrorStore();

    errorStore.setErrorCode(error.response?.status);

    const isBusinessError =
      axios.isAxiosError(error) &&
      ResponseError.isABusinessError(error.response?.data);

    if (isBusinessError) {
      throw new ResponseError(
        error.response?.status || 400,
        error.response?.data as BusinessError,
      );
    }

    throw error;
  },
);

export default api;
