<script setup lang="ts">
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import { HeaderType } from '@/shared/types/generic';

const { t } = useI18n();
const profileStore = useProfileStore();

const emit = defineEmits(['toggleModalStatus', 'goToLoginPage']);

const cancelModalTitle = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.title')
    : t('modal.cancel.title'),
);

const cancelModalBody = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.body')
    : t('modal.cancel.body'),
);

const cancelModalButton = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.button.backToRegister')
    : t('buttons.noResume'),
);

const cancelModalButtonLogin = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.button.cancelRegisto')
    : t('buttons.confirmCancel'),
);
</script>

<template>
  <div class="app-layout__app-modal">
    <AppModal>
      <template #header>{{ cancelModalTitle }}</template>
      <template #body>{{ cancelModalBody }}</template>
      <template #footer>
        <AppButton
          data-testid="close-modal"
          :label="cancelModalButton"
          @click="emit('toggleModalStatus')"
        />
        <AppButton
          type="danger"
          data-testid="cancel-flow"
          :label="cancelModalButtonLogin"
          @click="emit('goToLoginPage')"
        />
      </template>
    </AppModal>
  </div>
</template>

<style scoped lang="scss">
.app-layout__app-modal :deep(.app-modal-content) {
  border: 1px solid $red-500;
}
</style>
